import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

const splitTime = (s) => {
  let sumInSeconds = s > 0 ? s : 0;
  const oneMinute = 60;
  const oneHour = oneMinute * 60;
  const oneDay = oneHour * 24;

  const days = Math.floor(sumInSeconds / oneDay);
  sumInSeconds %= oneDay;

  const hours = Math.floor(sumInSeconds / oneHour);
  sumInSeconds %= oneHour;

  const minutes = Math.floor(sumInSeconds / oneMinute);
  sumInSeconds %= oneMinute;

  const seconds = sumInSeconds;

  const padWithZero = (num) => num.toString().padStart(2, "0");

  const result = {
    days: padWithZero(days),
    hours: padWithZero(hours),
    minutes: padWithZero(minutes),
    seconds: padWithZero(seconds),
  };

  return result;
};

const Timer = ({ time, translations }) => {
  const [timer, setTimer] = useState(splitTime(time));
  const { days, hours, minutes, seconds } = timer;
  const { timerDays, timerHours, timerMinutes, timerSeconds, timerTitle } =
    translations;
  const timerIntervalId = useRef();
  const customLinkId = localStorage.getItem("custom_link_id");

  const setDefaultData = () => {
    localStorage.setItem(
      "timer_data",
      JSON.stringify({
        customLinkId,
        timeCountdown: new Date().getTime(),
      })
    );
  };

  useLayoutEffect(() => {
    const timeLeft = localStorage.getItem("timer_data");

    if (timeLeft) {
      const timeLeftFromLocalStorage = JSON.parse(timeLeft);
      if (timeLeftFromLocalStorage.customLinkId === customLinkId) {
        const difference = Math.round(
          time -
            (new Date().getTime() - timeLeftFromLocalStorage.timeCountdown) /
              1000
        );

        setTimer(splitTime(difference));
      } else {
        setDefaultData();
      }
    } else {
      setDefaultData();
    }
  }, []);

  useEffect(() => {
    const timeLeft = localStorage.getItem("timer_data");

    if (timeLeft) {
      const timeLeftFromLocalStorage = JSON.parse(timeLeft);

      timerIntervalId.current = setInterval(() => {
        const difference = Math.round(
          time -
            (new Date().getTime() - timeLeftFromLocalStorage.timeCountdown) /
              1000
        );

        if (difference < 0) {
          clearInterval(timerIntervalId.current);
          return;
        }
        setTimer(splitTime(difference));
      }, 1000);
    }
  }, []);

  return (
    <>
      <div className="timer_title">{timerTitle}</div>
      <div className="timerWrap">
        <div className="timerItem">
          <div className="timer_numbers size">{days}</div>
          {timerDays ? <div className="timer_labels">{timerDays}</div> : null}
        </div>
        <div className="timer_dots size">&#58;</div>
        <div className="timerItem">
          <div className="timer_numbers size">{hours}</div>
          {timerHours ? <div className="timer_labels">{timerHours}</div> : null}
        </div>
        <div className="timer_dots size">&#58;</div>
        <div className="timerItem">
          <div className="timer_numbers size">{minutes}</div>
          {timerMinutes ? (
            <div className="timer_labels">{timerMinutes}</div>
          ) : null}
        </div>
        <div className="timer_dots size">&#58;</div>
        <div className="timerItem">
          <div className="timer_numbers size">{seconds}</div>
          {timerSeconds ? (
            <div className="timer_labels">{timerSeconds}</div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Timer;
