import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Radar from "../radar";
import SelectGender from "../selectGender";
import CitySelect from "../citySelect";
import Timer from "../timer";

const getUrl = (obj, path) =>
	`/app/${path ? path : ""}?${Object.keys(obj)
		.map((k) => `${k}=${obj[k]}`)
		.join("&")}`;

const App = (props) => {
	const {
		skipRequestGeolocation = 15,
		radarDuration = 5,
		geoSubtitle = "Allow geolocation to find people around to you that are looking for sex right now",
		myGender = "I am a",
		lookingGender = "Seeking a",
		geoBtnStart = "start",
		geoBntRequest = "... allow geolocation to start ...",
		geoBtnProgress = "... scanning in progress ...",
		citySelectPlaceholder = "Select City",
		noOptionsMessage = "Loading...",
		timerTitle,
		timerDays,
		timerHours,
		timerMinutes,
		timerSeconds,
		timerOffset = 0,
		timer = "off",
		radarCenter,
		radarCenterImg,
		radarPoint1Img,
		radarPoint2Img,
		radarPoint3Img,
		lookingGenderBlock = "off",
		lookingGenderOffText,
		scanningByIp = "off",
		scanningByIpText = "scanning By Ip",
	} = props;
	const [gender, setGender] = useState(0);
	const [preferences, setPreferences] = useState(1);
	const [location, setLocation] = useState(null);
	const [request, setRequest] = useState(false);
	const [requestRejected, setRequestRejected] = useState(false);
	const [startAnimate, setStartAnimate] = useState(false);
	const [showCitySelect, setShowCitySelect] = useState(false);
	const [city, setCity] = useState(null);
	const [inProcess, setInProcess] = useState(false);
	const timerId = useRef(null);
	const startScanningByIpTimerId = useRef(null);
	const variationPath = localStorage.getItem("variation_path");
	const customLinkId = localStorage.getItem("custom_link_id");

	const hasVariationPath = variationPath && variationPath === "true";

	const resetData = () => {
		setLocation(null);
		setRequest(false);
		setRequestRejected(false);
		setStartAnimate(false);
		setShowCitySelect(false);
		setCity(null);
		setInProcess(false);
	};

	const onPersistedPage = (e) => {
		if (e.persisted) {
			resetData();
		}
	};

	const requestLocation = () => {
		setRequest(true);

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setLocation({
						accuracy: position.coords.accuracy,
						altitude: position.coords.altitude,
						altitudeAccuracy: position.coords.altitudeAccuracy,
						heading: position.coords.heading,
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
						speed: position.coords.speed,
					});
					setRequest(false);
					setInProcess(true);
					window.localStorage.setItem(
						"userLocation",
						JSON.stringify({
							customLinkId,
							coordinates: {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							},
						})
					);
				},
				() => {
					setRequest(false);
					if (scanningByIp === "on") {
						setStartAnimate(true);
						setInProcess(true);
						setRequestRejected(true);
					} else {
						setShowCitySelect(true);
					}
				},
				{
					enableHighAccuracy: false,
					maximumAge: 2000,
				}
			);
		}
	};

	useEffect(() => {
		const userLocationFromLocalStorage =
			window.localStorage.getItem("userLocation");
		if (userLocationFromLocalStorage) {
			const userLocationData = JSON.parse(userLocationFromLocalStorage);
			if (userLocationData && userLocationData.customLinkId !== customLinkId) {
				window.localStorage.removeItem("userLocation");
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener("pageshow", onPersistedPage);

		return () => {
			window.removeEventListener("pageshow", onPersistedPage);
		};
	}, []);

	useEffect(() => {
		if (request && scanningByIp === "off") {
			timerId.current = setTimeout(() => {
				setShowCitySelect(scanningByIp !== "on");
				setStartAnimate(false);
				setLocation(null);
				setInProcess(false);
				setRequest(false);
			}, skipRequestGeolocation * 1000);
		}
		if (request && scanningByIp === "on") {
			startScanningByIpTimerId.current = setTimeout(() => {
				setStartAnimate(true);
				setInProcess(true);
				setRequest(false);
				setRequestRejected(true);
				if (startScanningByIpTimerId.current) {
					clearTimeout(startScanningByIpTimerId.current);
				}
			}, skipRequestGeolocation * 1000);
		}
	}, [request]);

	useEffect(() => {
		if (location) {
			setStartAnimate(true);
			if (hasVariationPath) {
				localStorage.setItem("landingIsVisited", "true");
			}
			setTimeout(() => {
				const newURL = getUrl(
					{ gender, preferences, ...location },
					hasVariationPath ? "people" : null
				);
				if (timerId.current) {
					clearTimeout(timerId.current);
				}
				if (startScanningByIpTimerId.current) {
					clearTimeout(startScanningByIpTimerId.current);
				}
				window.location.href = newURL;
			}, radarDuration * 1000);
		}
	}, [location]);

	useEffect(() => {
		if (city) {
			if (hasVariationPath) {
				localStorage.setItem("landingIsVisited", "true");
			}
			setTimeout(() => {
				const newURL = getUrl(
					{
						gender,
						preferences,
						cityId: city.value,
					},
					hasVariationPath ? "people" : null
				);
				if (timerId.current) {
					clearTimeout(timerId.current);
				}
				if (startScanningByIpTimerId.current) {
					clearTimeout(startScanningByIpTimerId.current);
				}
				window.location.href = newURL;
			}, radarDuration * 1000);
		}
	}, [startAnimate]);

	useEffect(() => {
		if (requestRejected) {
			if (hasVariationPath) {
				localStorage.setItem("landingIsVisited", "true");
			}
			setTimeout(() => {
				const newURL = getUrl(
					{
						gender,
						preferences,
					},
					hasVariationPath ? "people" : null
				);
				if (timerId.current) {
					clearTimeout(timerId.current);
				}
				if (startScanningByIpTimerId.current) {
					clearTimeout(startScanningByIpTimerId.current);
				}
				window.location.href = newURL;
			}, radarDuration * 1000);
		}
	}, [requestRejected]);

	useEffect(() => {
		localStorage.removeItem("landingIsVisited");
		return () => {
			setStartAnimate(false);
		};
	}, []);

	let btnText;

	if (request) {
		btnText = <span className="main_btn_text">{geoBntRequest}</span>;
	} else if (inProcess) {
		btnText = requestRejected ? (
			<span className="main_btn_text2">{scanningByIpText}</span>
		) : (
			<span className="main_btn_text">{geoBtnProgress}</span>
		);
	} else {
		btnText = <span className="main_btn_text">{geoBtnStart}</span>;
	}

	return (
		<div className="landing_v3_wrap">
			<div className="wrapper">
				{timer === "on" ? (
					<div className="timerWrapper">
						<Timer
							time={Number(timerOffset) || 0}
							translations={{
								timerDays,
								timerHours,
								timerMinutes,
								timerSeconds,
								timerTitle,
							}}
						/>
					</div>
				) : (
					<p className="subtitle">{geoSubtitle}</p>
				)}
				<Radar
					animate={startAnimate}
					radarCenter={radarCenter}
					radarCenterImg={radarCenterImg}
					profilePoints={[radarPoint1Img, radarPoint2Img, radarPoint3Img]}
				/>
				{lookingGenderBlock === "on" ? (
					<SelectGender
						gender={gender}
						preferences={preferences}
						setGender={(v) => setGender(v)}
						setPreferences={(v) => setPreferences(v)}
						myGender={myGender}
						lookingGender={lookingGender}
					/>
				) : (
					<>
						{lookingGenderOffText ? (
							<p className="looking_gender_off">{lookingGenderOffText}</p>
						) : null}
					</>
				)}
				{showCitySelect ? (
					<CitySelect
						setValue={(v) => setCity(v)}
						citySelectPlaceholder={citySelectPlaceholder}
						noOptionsMessage={noOptionsMessage}
					/>
				) : null}
				<button
					type="button"
					disabled={
						showCitySelect
							? !city
							: !(typeof gender === "number" && typeof preferences === "number")
					}
					className={`main_btn ${
						request || inProcess ? "removeAnimation" : ""
					}`}
					onClick={
						showCitySelect
							? () => {
									setStartAnimate(true);
									setInProcess(true);
							  }
							: () => requestLocation()
					}
				>
					{btnText}
				</button>
			</div>
		</div>
	);
};

export default App;
