import React from "react";

const SelectGender = (props) => {
	const {
		gender,
		preferences,
		setGender,
		setPreferences,
		myGender,
		lookingGender,
	} = props;

	return (
		<div className="select_gender">
			<div className="gender_col">
				<div className="text">{myGender}: </div>
				<div className="btn_wrap">
					<button
						type="button"
						className={`btn ${gender === 0 ? "select" : undefined} man`}
						onClick={() => setGender(0)}
					>
						<svg x="0" y="0" viewBox="0 0 36 36">
							<circle fill="none" cx="18" cy="18" r="17.5"></circle>
							<path d="M18 36C8.1 36 0 27.9 0 18S8.1 0 18 0s18 8.1 18 18-8.1 18-18 18zm0-35C8.6 1 1 8.6 1 18s7.6 17 17 17 17-7.6 17-17S27.4 1 18 1z"></path>
							<path d="M12.2 15.3v.2c.4 4 2.7 7.1 5.6 7.1 2.9 0 5.3-3.2 5.6-7.3 0-.3.1-.6.1-.9 0-.3 0-.6-.1-.9-.4-2.8-2.8-5-5.6-5-2.9 0-5.2 2.2-5.6 5 0 .3-.1.6-.1.9v.4c0 .2 0 .3.1.5zm2.1-3.2s3.9 1.9 6.6 0c0 0 1.6 2.5 1.1 3.4l.8-.9c0 .2 0 .5-.1.7-.3 3.6-2.4 6.4-4.9 6.4-2.5 0-4.6-2.7-4.9-6.2.2.1.5.2.8.4 0 0-.6-3.3.6-3.8z"></path>
							<path d="M29.9 30.9s0-3.7-2.5-6.4c-1.2-1.4-3-2.4-4.9-2.5 0 1.3-2 2.4-4.5 2.4s-4.5-1.1-4.5-2.4c-2 .2-3.7 1.1-4.9 2.5-2.5 2.7-2.5 6.4-2.5 6.4 3.2 2.9 7.3 4.6 11.9 4.6 4.6 0 8.8-1.8 11.9-4.6z"></path>
						</svg>
					</button>
					<button
						type="button"
						className={`btn ${gender === 1 ? "select" : undefined} woman`}
						onClick={() => setGender(1)}
					>
						<svg x="0" y="0" viewBox="0 0 36 36">
							<circle fill="none" cx="18" cy="18" r="17.5"></circle>
							<path d="M29 28.4c-1.3-4.2-3.6-4.3-4.1-4.4h-.2c-1.5-1.4-.5-7.3-.5-7.3 0-9-6.1-8.1-6.1-8.1h-.4s-6.1-.9-6.1 8.1c0 0 1 5.9-.5 7.3h-.2c-.5.1-2.8.3-4.1 4.5 0 0-.6 1.2-.4 2.8l.3.3c3 2.5 6.9 4 11.1 4 2.2 0 4.2-.4 6.1-1.1 2-.7 3.7-1.8 5.3-3.1.4-1.7-.2-3-.2-3zm-6.9-4.8c0 .2-.1.3-.2.5-.5.7-2.1 1.7-3.9 1.7-1.8 0-3.4-1-3.9-1.7-.1-.1-.2-.3-.2-.5 0 0 .1 0 .2-.1 0 0 .1 0 .1-.1.3-.2.8-.5.8-1.2.5.5 1.1.9 1.7 1.2.4.2.9.3 1.3.3.5 0 .9-.1 1.3-.3.6-.2 1.2-.7 1.7-1.2.1.9.9 1.3 1.1 1.4zm1-6.1c-.1.5-.3.8-.5 1-.1.1-.2.1-.2.1s-.1 0-.1-.1c-.4 1.3-1.3 2.7-2.3 3.5-.6.5-1.3.7-2 .7s-1.4-.3-2-.8c-1-.8-1.8-2.1-2.3-3.4l-.1.1c-.1 0-.2 0-.3-.1-.2-.2-.3-.5-.4-1-.1-.6 0-1.1.2-1.2h.1c.1 0 .1 0 .2.1 0-.2.4-3 2.4-2.8l6.4 3.3.5-.5h.1c.3-.1.4.5.3 1.1z"></path>
							<path d="M18 36C8.1 36 0 27.9 0 18S8.1 0 18 0s18 8.1 18 18-8.1 18-18 18zm0-35C8.6 1 1 8.6 1 18s7.6 17 17 17 17-7.6 17-17S27.4 1 18 1z"></path>
						</svg>
					</button>
				</div>
			</div>
			<div className="gender_col">
				<div className="text">{lookingGender}: </div>
				<div className="btn_wrap">
					<button
						type="button"
						className={`btn ${preferences === 0 ? "select" : undefined} man`}
						onClick={() => setPreferences(0)}
					>
						<svg x="0" y="0" viewBox="0 0 36 36">
							<circle fill="none" cx="18" cy="18" r="17.5"></circle>
							<path d="M18 36C8.1 36 0 27.9 0 18S8.1 0 18 0s18 8.1 18 18-8.1 18-18 18zm0-35C8.6 1 1 8.6 1 18s7.6 17 17 17 17-7.6 17-17S27.4 1 18 1z"></path>
							<path d="M12.2 15.3v.2c.4 4 2.7 7.1 5.6 7.1 2.9 0 5.3-3.2 5.6-7.3 0-.3.1-.6.1-.9 0-.3 0-.6-.1-.9-.4-2.8-2.8-5-5.6-5-2.9 0-5.2 2.2-5.6 5 0 .3-.1.6-.1.9v.4c0 .2 0 .3.1.5zm2.1-3.2s3.9 1.9 6.6 0c0 0 1.6 2.5 1.1 3.4l.8-.9c0 .2 0 .5-.1.7-.3 3.6-2.4 6.4-4.9 6.4-2.5 0-4.6-2.7-4.9-6.2.2.1.5.2.8.4 0 0-.6-3.3.6-3.8z"></path>
							<path d="M29.9 30.9s0-3.7-2.5-6.4c-1.2-1.4-3-2.4-4.9-2.5 0 1.3-2 2.4-4.5 2.4s-4.5-1.1-4.5-2.4c-2 .2-3.7 1.1-4.9 2.5-2.5 2.7-2.5 6.4-2.5 6.4 3.2 2.9 7.3 4.6 11.9 4.6 4.6 0 8.8-1.8 11.9-4.6z"></path>
						</svg>
					</button>
					<button
						type="button"
						className={`btn ${preferences === 1 ? "select" : undefined} woman`}
						onClick={() => setPreferences(1)}
					>
						<svg x="0" y="0" viewBox="0 0 36 36">
							<circle fill="none" cx="18" cy="18" r="17.5"></circle>
							<path d="M29 28.4c-1.3-4.2-3.6-4.3-4.1-4.4h-.2c-1.5-1.4-.5-7.3-.5-7.3 0-9-6.1-8.1-6.1-8.1h-.4s-6.1-.9-6.1 8.1c0 0 1 5.9-.5 7.3h-.2c-.5.1-2.8.3-4.1 4.5 0 0-.6 1.2-.4 2.8l.3.3c3 2.5 6.9 4 11.1 4 2.2 0 4.2-.4 6.1-1.1 2-.7 3.7-1.8 5.3-3.1.4-1.7-.2-3-.2-3zm-6.9-4.8c0 .2-.1.3-.2.5-.5.7-2.1 1.7-3.9 1.7-1.8 0-3.4-1-3.9-1.7-.1-.1-.2-.3-.2-.5 0 0 .1 0 .2-.1 0 0 .1 0 .1-.1.3-.2.8-.5.8-1.2.5.5 1.1.9 1.7 1.2.4.2.9.3 1.3.3.5 0 .9-.1 1.3-.3.6-.2 1.2-.7 1.7-1.2.1.9.9 1.3 1.1 1.4zm1-6.1c-.1.5-.3.8-.5 1-.1.1-.2.1-.2.1s-.1 0-.1-.1c-.4 1.3-1.3 2.7-2.3 3.5-.6.5-1.3.7-2 .7s-1.4-.3-2-.8c-1-.8-1.8-2.1-2.3-3.4l-.1.1c-.1 0-.2 0-.3-.1-.2-.2-.3-.5-.4-1-.1-.6 0-1.1.2-1.2h.1c.1 0 .1 0 .2.1 0-.2.4-3 2.4-2.8l6.4 3.3.5-.5h.1c.3-.1.4.5.3 1.1z"></path>
							<path d="M18 36C8.1 36 0 27.9 0 18S8.1 0 18 0s18 8.1 18 18-8.1 18-18 18zm0-35C8.6 1 1 8.6 1 18s7.6 17 17 17 17-7.6 17-17S27.4 1 18 1z"></path>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

export default SelectGender;
