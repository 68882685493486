import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";

const CitySelect = (props) => {
  const { setValue, citySelectPlaceholder, noOptionsMessage } = props;
  const [localValue, setLocalValue] = useState([]);
  const [open, setOpen] = useState(false);
  const timer = useRef(null);

  const getCities = (str) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (str.length) {
      timer.current = setTimeout(() => {
        axios({
          url: "/api/v2/general/cities",
          method: "GET",
          params: {
            name: str,
            by_ip: true,
          },
        }).then((r) =>
          setLocalValue(
            r.data
              .map((city) => ({
                value: city.id,
                label: city.name,
              }))
              .sort((a, b) => {
                if (a.label > b.label) {
                  return 1;
                }
                if (a.label < b.label) {
                  return -1;
                }
                return 0;
              })
          )
        );
      }, 500);
    }
  };

  return (
    <div className="citySelect_wrap">
      <Autocomplete
        open={open}
        autoSelect
        disablePortal
        fullWidth
        noOptionsText={noOptionsMessage}
        options={localValue}
        onInputChange={(e, search) => {
          const formattedSearch = search.trim();
          setOpen(Boolean(formattedSearch.length));
          getCities(formattedSearch);
        }}
        onChange={(e, city) => {
          setValue(city);
          setOpen(false);
        }}
        renderInput={(params) => (
          <TextField {...params} label={citySelectPlaceholder} />
        )}
        classes={{
          root: "customRoot",
          popper: "popper",
          paper: "paper",
          inputRoot: "inputRoot",
          inputFocused: "inputFocused",
          noOptions: "noOptions",
        }}
      />
    </div>
  );
};

export default CitySelect;
