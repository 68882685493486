import React from "react";

const Radar = ({ animate, radarCenter, radarCenterImg, profilePoints }) => {
	const points = profilePoints.map((img, index) => (
		<div key={index} className={`radar_point_${index + 1}`}>
			{img ? (
				<div className="profileImgWrapper">
					<img className="profileImg" src={img} alt="" />
				</div>
			) : (
				<div className={`point_${index + 1}`} />
			)}
		</div>
	));

	return (
		<div className="radar_wrap">
			<div className={`radar_bg ${animate ? "radar_animate" : undefined}`}>
				<div className="radar_line" />
				<div className="radar_circle_1" />
				<div className="radar_circle_2" />
				<div className="radar_centerCircle"></div>
			</div>
			<div className={animate ? "radar_animation" : undefined}>{points}</div>
			<div className="user_wrap">
				{radarCenterImg ? (
					<div className="img_wrap">
						<img className="img" src={radarCenterImg} alt="" />
					</div>
				) : null}
				{radarCenter ? <div className="text">{radarCenter}</div> : null}
			</div>
		</div>
	);
};

export default Radar;
