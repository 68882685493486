import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app";
import { StyledEngineProvider } from "@mui/material";

ReactDOM.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<App {...settings} />
		</StyledEngineProvider>
	</React.StrictMode>,
	document.getElementById("landing_v3")
);
